<template>
    <div class="record-pagination">
        <p class="record-pagination-total">共{{ totalItem }}条数据</p>
        <el-pagination background layout="prev, pager, next" :total="totalItem" hide-on-single-page
            @current-change="onPageChanged" :pageSize="6">
        </el-pagination>
    </div>
</template>
  
<script>
import { Pagination } from "element-ui";
export default {
    components: {
        elPagination: Pagination,
    },

    props: {
        result: Object
    },

    computed: {
        totalItem() {
            return this.result?.total ?? 0
        }
    },

    methods: {
        onPageChanged(currentPage) {
            this.$emit("onPageChanged", currentPage)
        },
    }


};
</script>
  
<style lang="scss" scoped>
.record-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    &-total {
        color: #666666;
        font-size: 14px;
    }

}
</style>