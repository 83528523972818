<template>
  <div class="record">
    <record-bar @onAdd="onAddRecord" @paramChanged="onParamChanged"></record-bar>
    <record-table :dataArray="dataArray" @onCheckRecord="onCheckRecord"></record-table>
    <div style="flex:1"></div>
    <pagination :result="recordListResult" @onPageChanged="onPageChanged"></pagination>
    <add-record-alert v-if="isShowAddRecordAlert" @onConfirm="onAddRecordAlertConfirm" @onClose="onAddRecordAlertClose">
    </add-record-alert>
  </div>
</template>

<script>
import RecordBar from "@c/user-main/record/record-bar/record-bar.vue";
import RecordTable from "@c/user-main/record/record-table/record-table.vue";
import Pagination from "@c/user-main/record/pagination.vue";
import AddRecordAlert from "@c/common/alert/panel-alert/panel-alert-add-record.vue";
export default {
  components: {
    RecordBar,
    RecordTable,
    Pagination,
    AddRecordAlert
  },

  props: {
    patientDetailModel: Object
  },

  data() {
    return {
      uid: undefined,
      recordListResult: undefined,
      isShowAddRecordAlert: false,
      //record-list-param
      templateType: null,
      recordState: null,
      keyword: null,
      startTime: null,
      endTime: null,
      pageNum: 1,

    }
  },

  computed: {
    dataArray() {
      return this.recordListResult?.list ?? []
    }
  },

  async mounted() {
    this.uid = parseInt(this.$router.history.current.query.uid)
    this.getRecordList()

    window["updateRecordList"] = () => {
      this.getRecordList()
    };
  },

  methods: {
    onParamChanged(params) {
      this.templateType = params.templateType
      this.recordState = params.recordState
      this.keyword = params.keyword
      this.startTime = params.startTime
      this.endTime = params.endTime
      this.getRecordList()
    },

    onPageChanged(currentPage) {
      this.pageNum = currentPage
      this.getRecordList()
    },

    onCheckRecord(model) {
      this.navigatorToRecordFrame({
        id: model.id
      })
    },

    //AddRecordAlert
    onAddRecord() {
      this.isShowAddRecordAlert = true
    },

    onAddRecordAlertConfirm(recordType) {
      this.isShowAddRecordAlert = false
      this.navigatorToRecordFrame({
        patientId: this.uid,
        hospitalid: this.patientDetailModel.model.hospitalId,
        templateType: recordType,
        recordState: RECORD_STATUS.草稿
      })
    },

    onAddRecordAlertClose() {
      this.isShowAddRecordAlert = false

    },

    navigatorToRecordFrame(model) {
      let routeData = this.$router.resolve({
        name: "RecordFrame",
        query: {
          id: model.id,
          patientId: model.patientId,
          hospitalid: model.hospitalid,
          templateType: model.templateType,
          recordState: model.recordState
        },
      });
      window.open(routeData.href, "_blank");
    },

    async getRecordList() {
      const params = {
        "pageNum": this.pageNum,
        "templateType": this.templateType,
        "pageSize": 6,
        "recordState": this.recordState,
        "patientId": this.uid,
        "keyWord": this.keyword,
        "startTime": this.startTime,
        "endTime": this.endTime
      }
      try {
        this.recordListResult = await this.$api.getPatientRecordList(params)
      } catch (error) {
        this.$toast.showRed(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.record {
  height: 700px;
  display: flex;
  flex-direction: column;

  &-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    &-total {
      color: #666666;
      font-size: 14px;
    }

  }
}
</style>