import { findKey } from "@js/object-tool.js";

class RecordItemModel  {
    // constructor
    constructor(model) {
        this.model = model
        this.createDateStr = placeholderText(this.model.gmtCreate)
        this.recordTypeStr = this._initRecordTypeStr()
        this.description = placeholderText(this.model.briefIntroduction)
    }

    // init
    _initRecordTypeStr() {
        return placeholderText(findKey(RECORD_TYPE,this.model.templateType))
    }

}

export { RecordItemModel };