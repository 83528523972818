<template>
    <div class="status-label">
        <p class="status-label-content" :class="statusClass">{{ statusStr }}</p>
    </div>
</template>

<script>
import { findKey } from "@js/object-tool.js";
export default {
    props: {
        status: Number
    },

    computed: {
        statusStr() {
            return placeholderText(findKey(RECORD_STATUS, this.status))
        },

        statusClass() {
            switch (this.status) {
                case RECORD_STATUS.草稿:
                    return "draft"
                case RECORD_STATUS.已撤销:
                    return "canceled"
                case RECORD_STATUS.已发布:
                    return "published"
                default:
                    return "";
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.status-label {
    margin-left: 0px !important;

    &-content {
        font-size: 12px;
        border-radius: 3px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.draft {
    background-color: #ECF2FE;
    color: #0052D9;
    border: 1px solid #0052D9;
    width: 40px;
}

.canceled {
    background-color: #FDECEE;
    color: #E34D59;
    border: 1px solid #E34D59;
    width: 52px;
}

.published {
    background-color: #E8F8F2;
    color: #00A870;
    border: 1px solid #00A870;
    width: 52px;
}
</style>
