<template>
    <div class="record-date-selector">
        <p class="record-date-selector-title">创建日期:</p>
        <el-date-picker v-model="date" type="daterange" :append-to-body="false" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
    </div>
</template>

<script>
import { DatePicker } from "element-ui";
export default {
    components: {
        elDatePicker: DatePicker,
    },

    data() {
        return {
            date: null
        }
    },

    watch: {
        date(value) {
            this.$emit("onDateSelected", value)
        }
    }
}
</script>

<style lang="scss" scoped>
.record-date-selector {
    display: flex;
    align-items: center;
    margin-right: 25px;

    &-title {
        color: black;
        font-size: 16px;
        margin-right: 10px;
    }

    &-add {
        background-color: white;
        color: #2E6BE6;
        border: 1px solid #2E6BE6;
        border-radius: 6px;
        width: 84px;
        height: 32px;
    }
}
</style>