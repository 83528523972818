<template>
    <div class="record-table"
        :style="`width: ${100 / zoom}% ; height: ${400 * zoom}px ;zoom:${1 / zoom};transform:scale(${zoom});transform-origin:0 0`">
        <el-table :data="proecssedDataArray" border tooltip-effect="light myTooltips">
            <el-table-column prop="createDateStr" label="创建日期" min-width="236/1318%">
            </el-table-column>
            <el-table-column prop="recordTypeStr" label="记录类型" min-width="212/1318%">
            </el-table-column>
            <el-table-column prop="model.recordState" label="记录状态" min-width="180/1318%">
                <template slot-scope="scope">
                    <status-label :status="scope.row.model.recordState"></status-label>
                </template>
            </el-table-column>
            <el-table-column prop="description" label="记录摘要" min-width="510/1318%" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="操作" min-width="180/1318%">
                <template slot-scope="scope">
                    <button class="record-table-check-button" @click="onCheckRecord(scope.row)">查看</button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
  
<script>
import { Table, TableColumn } from "element-ui";
import StatusLabel from "@c/user-main/record/record-table/status-label.vue";
import { RecordItemModel } from "@c/user-main/record/record-table/record-item-model.js";
export default {
    components: {
        elTable: Table,
        elTableColumn: TableColumn,
        StatusLabel,
    },

    props: {
        dataArray: Array
    },

    computed: {
        zoom() {
            return this.$store.state.zoom
        },

        proecssedDataArray() {
            return this.dataArray.map((item) => {
                return new RecordItemModel(item)
            })
        }
    },

    mounted() {
        //解决tooltip偏移问题（结合chart的style）
        const app = document.getElementById("app");
    },

    methods: {
        onCheckRecord(row) {
            this.$emit("onCheckRecord", row.model) //原始值
        },
    }


};
</script>
  
<style lang="scss" scoped>
.record-table {
    height: 700px;

    //table
    /deep/.el-table--border {
        border: 1px solid #EBEEF5 !important;
    }

    //header-cell
    /deep/.el-table th.el-table__cell>.cell {
        color: rgba($color: #000000, $alpha: 0.4);
        font-size: 14px;
        font-weight: normal;
        // height: 32px;
        display: flex;
        align-items: center;
        margin-left: 24px;
    }


    //cell
    /deep/ .el-table td.el-table__cell div {
        color: rgba($color: #000000, $alpha: 0.9);
        font-size: 14px;
        margin-left: 24px;
    }



    &-status-label {
        background-color: #ECF2FE;
        color: #0052D9;
        font-size: 12px;
        border: 1px solid #0052D9;
        border-radius: 3px;
        width: 40px;
        height: 24px;
    }

    &-check-button {
        color: #0052D9;
    }
}
</style>

<!-- 自定义tooltip -->
<style lang="scss">
.myTooltips {
    max-width: 314px;
    border: none !important;
    box-shadow: 0px 5px 5px -3px rgba(black, 0.1), 0px 8px 10px 1px rgba(black, 0.06), 0px 3px 14px 2px rgba(black, 0.05);
}
</style>