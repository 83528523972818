<template>
    <div class="record-selector">
        <p class="record-selector-title">{{ title }}:</p>
        <el-select v-model="value" :popper-append-to-body="false">
            <el-option v-for="item in dataArray" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
    </div>
</template>

<script>
import { Select, Option } from "element-ui";
export default {

    model: {
        prop: "initValue",
        event: "onChanged"
    },

    components: {
        elSelect: Select,
        elOption: Option,
    },

    props: {
        title: String,
        dataArray: Array,
        initValue: Number
    },

    data() {
        return {
            value: null
        }
    },

    mounted() {
        if (!isNaN(this.initValue)) {
            this.value = this.initValue
        }
    },

    watch: {
        initValue(value) {
            if (!isNaN(value)) {
                this.value = value
            }

        },

        value(value) {
            this.$emit("onChanged", value)
        }
    }
}
</script>

<style lang="scss" scoped>
.record-selector {
    display: flex;
    align-items: center;
    margin-right: 25px;

    &-title {
        color: black;
        font-size: 16px;
        margin-right: 10px;
    }

}
</style>