<template>
    <div class="record-bar">
        <button class="record-bar-add" @click="onAdd">新增记录</button>
        <div class="record-bar-right">
            <record-selector title="记录类型" :dataArray="recordTypeArray" v-model="templateType"></record-selector>
            <record-selector title="报告状态" :dataArray="recordStatusArray" v-model="recordState"></record-selector>
            <record-date-selector @onDateSelected="onDateSelected"></record-date-selector>
            <el-input placeholder="支持关键字查询" suffix-icon="el-icon-search" clearable v-model="keyword"
                @keyup.enter.native="onKeywordInputed" @clear="onKeywordInputed">
            </el-input>
        </div>
    </div>
</template>

<script>
import { Select, Option, Input } from "element-ui";
import RecordSelector from "@c/common/selector/record-selector.vue";
import RecordDateSelector from "@c/user-main/record/record-bar/record-date-selector.vue";
import { DateTool } from "@js/date-tool.js";
export default {
    components: {
        elSelect: Select,
        elOption: Option,
        elInput: Input,
        RecordSelector,
        RecordDateSelector
    },

    data() {
        return {
            templateType: null,
            recordState: null,
            date: null,
            keyword: null,
            recordTypeArray: [{
                value: null,
                label: '全部'
            }, {
                value: 1,
                label: '压力滴定'
            }, {
                value: 2,
                label: '适宜性评估'
            }, {
                value: 3,
                label: '病史病历'
            }],
            recordStatusArray: [{
                value: null,
                label: '全部'
            }, {
                value: 1,
                label: '草稿'
            }, {
                value: 2,
                label: '发布'
            }, {
                value: 3,
                label: '撤销'
            }]
        }
    },

    computed: {
        startTime() {
            if (this.date == null) {
                return null
            } else {
                return DateTool.dateToStr(this.date[0])
            }
        },
        endTime() {
            if (this.date == null) {
                return null
            } else {
                return DateTool.dateToStr(this.date[1])
            }
        }
    },

    watch: {
        templateType() {
            this.paramChanged()
        },

        recordState() {
            this.paramChanged()
        }
    },

    methods: {
        onAdd() {
            this.$emit("onAdd")
        },

        // onSelected(model) {
        //     switch (model.title) {
        //         case "记录类型":
        //             this.templateType = model.value
        //             break;
        //         case "报告状态":
        //             this.recordState = model.value
        //             break;
        //         default:
        //             break;
        //     }
        //     this.paramChanged()

        // },

        onDateSelected(date) {
            this.date = date
            this.paramChanged()
        },

        onKeywordInputed() {
            this.paramChanged()
        },

        paramChanged() {
            this.$emit("paramChanged", {
                templateType: this.templateType,
                recordState: this.recordState,
                startTime: this.startTime,
                endTime: this.endTime,
                keyword: this.keyword == '' ? null : this.keyword
            })
        }
    }

}
</script>

<style lang="scss" scoped>
.record-bar {
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-add {
        background-color: white;
        color: #2E6BE6;
        border: 1px solid #2E6BE6;
        border-radius: 6px;
        width: 84px;
        height: 32px;
    }

    &-right {
        display: flex;
    }

    /deep/.el-input {
        width: 247px;
        margin-left: 8px;
    }
}
</style>