<template>
    <panel-alert title="选择模版" width="547px" isShowBottom @onCloseClick="onClose" @onConfirmClick="onConfirm">
        <template v-slot:content>
            <div class="panel-alert-add-record">
                <el-radio v-model="recordType" :label="1">压力滴定</el-radio>
                <p class="panel-alert-add-record-info">在滴定记录中选择一份数据，在此基础上发布报告</p>
                <el-radio v-model="recordType" :label="2">适宜性评估</el-radio>
                <p class="panel-alert-add-record-info">根据多天治疗数据，对比基线数据，评估是否适合长期NPPV治疗</p>
                <el-radio v-model="recordType" :label="3">病史病历</el-radio>
                <p class="panel-alert-add-record-info">患者病程、治疗效果与服务记录</p>
            </div>
        </template>
    </panel-alert>
</template>
  
<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import { Radio } from "element-ui";
export default {
    components: {
        PanelAlert,
        elRadio: Radio
    },

    data() {
        return {
            recordType: 1
        }
    },

    methods: {
        onEdit() {
            this.$emit("onEdit");
        },

        onClose() {
            this.$emit("onClose");
        },

        onConfirm() {
            this.$emit("onConfirm", this.recordType);
        },

    },
};
</script>
  
<style lang="scss" scoped>
.panel-alert-add-record {
    margin-top: 7px;

    //radio
    /deep/.el-radio__label {
        color: black;
        font-size: 16px;
    }

    &-info {
        color: #8B8B8B;
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 25px;
        margin-left: 23px;
    }
}
</style>